import { notification } from 'antd';
import { AxiosError } from 'axios';
import Axios from '../../../axios/axios';

export const getTaskConfig = () => async (dispatch) => {
    dispatch({
        type: 'LOADING_TASKS',
        payload: true,
    });

    try {
        const response = await Axios.get('/taskConfig');

        if (response.data.message !== 'There are not tasks to show') {
            response.data = response.data;
        } else {
            response.data = [];
        }

        console.log(response, 'response');

        dispatch({
            type: 'GET_TASKS',
            payload: response?.data,
        });
    } catch (error) {
        notification.error({
            message: 'Ocurrio un error.',
            description: '¡No se pudieron cargar las tareas!',
        });
    } finally {
        dispatch({
            type: 'LOADING_TASKS',
            payload: false,
        });
    }
};
