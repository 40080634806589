import { useState, useEffect } from 'react';

const useFilterUnique = ({ data = [], parameters = [] }) => {
    const [items, setItems] = useState<any>();
    const itemsFiltered: any[] = [];

    useEffect(() => {
        data?.forEach((element) => {
            // itemsFiltered.push(
            //     ...(element?.tasks.map((el) => el.userName) ||
            //         element.userName),
            // );
        });
        filterUniqueItems(itemsFiltered);
    }, [data]);

    const filterUniqueItems = (item: string[]) => {
        const uniqueItem: any[] = [];
        const unique = item.filter((c, index) => {
            return item.indexOf(c) === index;
        });

        unique.forEach((element) => {
            uniqueItem.push({ text: element, value: element });
        });
        setItems(uniqueItem);
    };

    return { items };
};

export default useFilterUnique;
