import { AnyAction } from 'redux';

interface Fields {
    fields: string[];
    loading: boolean;
}

const initialState: Fields = {
    fields: [],
    loading: false,
};

export const GetFieldTask = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case 'GET_FIELDS_TASKS':
            return {
                ...state,
                fields: action.payload,
            };
        case 'LOADING_FIELDS_TASKS':
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};
