import React, { useState, useEffect, Fragment } from 'react';
import {
    Modal,
    Form,
    Input,
    // InputNumber,
    Switch,
    DatePicker,
    Select,
    Button,
    Divider,
    Row,
    Col,
    Typography,
    Alert,
    Space,
} from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import { RootState } from '@store/index';
import { getAllUsers } from '@store/Actions/GetUsers';
import { createNewTask } from '@store/Actions/CreateTask';
import { SelectInput } from './SelectInput';
import { getTasks } from '@store/Actions/GetTasks';

interface Props {
    modalVisible: boolean;
    closeModal: () => void;
    getAllUsers: () => void;
    createNewTask: (
        taskName: string,
        description: string,
        duration: number,
        isTaskGroup: boolean,
        usersId: number[],
        inputsFields: [
            {
                inputFieldId: number;
                isRequired: boolean;
                min: number;
                max: number;
                name: string;
            },
        ],
    ) => void;
    collaborators: any[];
    loading: boolean;
    getTasks: () => void;
}

type Date = Moment | null;

const CreateTask: React.FC<Props> = ({
    modalVisible,
    closeModal,
    getAllUsers,
    collaborators,
    createNewTask,
    loading,
    getTasks,
}): JSX.Element => {
    const { Option } = Select;
    const { TextArea } = Input;
    const { Text } = Typography;
    const [form] = Form.useForm();

    const [taskGroup, setTaskGroup] = useState<boolean>(false);
    const [deadLine, setDeadLine] = useState<any>(0);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        getAllUsers();
    }, []);

    const onOk = async () => {
        const values = await form.validateFields();
        console.log(values, 'values');

        if (values.inputs?.length == 0 || values?.inputs == undefined) {
            setError(true);
            return;
        }

        const deadLineSeconds = Math.floor(deadLine / 1000) || deadLine;

        await createNewTask(
            values.title,
            values.description,
            deadLineSeconds,
            values.groupTask,
            values.collaborators,
            values.inputs,
        );

        form.resetFields();
        closeModal();
        getTasks();
    };

    const handleAdd = (add) => {
        add();
        setError(false);
    };

    const disabledDate = (current: Moment): boolean => {
        // Can not select days before today

        return current && current < moment().startOf('day');
    };

    const range = (start, end) => {
        const result: any = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };

    const disabledTime = (current) => {
        if (current && current.date() > new Date().getDate()) {
            return {};
        }
        return {
            disabledHours: () => range(0, current?.hour() || 0),
            disabledMinutes: () => range(0, current?.minute() || 0),
        };
    };

    const options = collaborators.map((collaborator) => (
        <Option
            value={collaborator.userId}
            key={collaborator.userId}
            label={`${collaborator.name} ${collaborator.lastName}`}
        >
            {collaborator.name} {collaborator.lastName}
        </Option>
    ));

    return (
        <>
            <Modal
                visible={modalVisible}
                onCancel={closeModal}
                confirmLoading={loading}
                title="Nueva tarea"
                onOk={onOk}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        label="Titulo"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Ingrese el titulo de la tarea',
                            },
                        ]}
                    >
                        <Input placeholder="Titulo de la tarea" />
                    </Form.Item>
                    <Form.Item
                        label="Descripcion"
                        name="description"
                        rules={[
                            {
                                required: true,
                                message: 'Ingrese la descripcion de la tarea',
                            },
                        ]}
                    >
                        <TextArea placeholder="Descripcion de la tarea" />
                    </Form.Item>
                    <Form.Item
                        label="Fecha limite"
                        name="deadline"
                        rules={[
                            {
                                required: true,
                                message: 'Ingrese la fecha de la tarea',
                            },
                        ]}
                    >
                        <DatePicker
                            placeholder="Fecha limite de la tarea"
                            showTime
                            onChange={(date: Date) =>
                                setDeadLine(date?.valueOf())
                            }
                            disabledDate={disabledDate}
                            disabledTime={disabledTime}
                            format="YYYY/MM/DD HH:mm:ss"
                            style={{ width: '100%' }}
                            showSecond={false}
                        />
                    </Form.Item>

                    <Row gutter={24}>
                        <Col xs={24} sm={19}>
                            <Form.Item
                                label="Colaboradores"
                                name="collaborators"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Ingrese al menos 1 colaborador',
                                    },
                                ]}
                            >
                                <Select
                                    optionFilterProp="label"
                                    mode="multiple"
                                    placeholder="Nombre de colaboradores"
                                >
                                    {options}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={5}>
                            <Form.Item
                                label="Tarea grupal"
                                name="groupTask"
                                initialValue={false}
                            >
                                <Switch
                                    checked={taskGroup}
                                    onChange={setTaskGroup}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.List name="inputs">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <Fragment key={field.key}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Text type="secondary">
                                                {index + 1}.
                                            </Text>
                                            <CloseOutlined
                                                style={{ color: '#546B81' }}
                                                onClick={() =>
                                                    remove(field.name)
                                                }
                                            />
                                        </div>
                                        <Form.Item
                                            label="Nombre del campo"
                                            name={[field.name, 'name']}
                                            fieldKey={field.fieldKey}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Ingrese el nombre del campo',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item fieldKey={field.fieldKey}>
                                            <SelectInput field={field} />
                                        </Form.Item>

                                        <Divider />
                                    </Fragment>
                                ))}
                                <Button
                                    type="dashed"
                                    onClick={() => handleAdd(add)}
                                    block
                                    icon={<PlusOutlined />}
                                >
                                    Añadir campo
                                </Button>
                                {error && (
                                    <div style={{ marginTop: '1em' }}>
                                        <Alert
                                            message="Debes de añadir por lo menos un campo"
                                            type="error"
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </Form.List>
                </Form>
            </Modal>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        collaborators: state.ActiveUsers.activeUsers,
        loading: state.CreateTask.loading,
    };
};

const mapDispatchToProps = {
    getAllUsers,
    createNewTask,
    getTasks,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTask);
