/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Dropdown, Menu, Skeleton } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { getTasks } from '@store/Actions/GetTasks';
import { RootState } from '@store/index';
import UpdateTask from '@components/UpdateTask';
import CardFilter from '@components/CardFilter';
import NoResults from '@assets/icons/no-results.png';
import { useShowItem } from '../../hooks/useShow';

interface IProps {
    getTasks: () => void;
    tasks: any;
    loading: boolean;
    taskCard: [];
}

interface Users {
    file: string | null;
    finishedDate: number | null;
    isFile: boolean;
    isLocation: boolean;
    isNumericInput: boolean;
    isPhoto: boolean;
    isTextInput: boolean;
    location: number | null;
    numericinput: number | null;
    photo: string | null;
    taskId: number;
    textinput: string | null;
    userId: number;
    userLastName: string;
    userName: string;
    nameComplete: string;
    formattedFinishedDate: string;
}

export interface ITask {
    createdDate: number;
    description: string;
    duration: number;
    franchiseId: number;
    franchiseName: string;
    isCompleted: number;
    isNew: number;
    isOnTime: number;
    taskConfId: number;
    taskConfName: string;
    key: number;
    status: string;
    formattedDate: string;
    formattedFinishDate: string;
    users: [Users];
}

const TaskCard: React.FC<IProps> = ({
    getTasks,
    tasks,
    loading,
    taskCard,
}): JSX.Element => {
    const { show, handleChange } = useShowItem();

    const franchiseId = Cookies.get('franchiseId');
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('');
    const [data, setData] = useState<ITask[]>([]);
    //need ITask
    const [rowData, setRowData] = useState<ITask>({
        createdDate: 0,
        description: '',
        duration: 0,
        franchiseId: 0,
        franchiseName: '',
        isCompleted: 0,
        isNew: 0,
        isOnTime: 0,
        taskConfId: 0,
        taskConfName: '',
        key: 0,
        formattedDate: '',
        formattedFinishDate: '',
        status: '',
        users: [
            {
                file: null,
                finishedDate: null,
                isFile: false,
                isLocation: false,
                isNumericInput: false,
                isPhoto: false,
                isTextInput: false,
                location: null,
                numericinput: null,
                photo: null,
                taskId: 0,
                textinput: null,
                userId: 0,
                userLastName: '',
                userName: '',
                nameComplete: '',
                formattedFinishedDate: '',
            },
        ],
    });

    useEffect(() => {
        getTasks();
    }, []);

    const closeModal = () => {
        setModalVisible(false);
    };

    const menu = (task: ITask) => (
        <Menu
            onClick={() => {}}
            //  key={task.tasks[0].taskId}
        >
            <Menu.Item key="0">
                <a onClick={() => handleUpdate(task)}>Editar</a>
            </Menu.Item>
        </Menu>
    );

    const handleUpdate = (task: ITask) => {
        setModalVisible(true);
        setRowData(task);
    };

    const searchFilter = (search) => {
        setQuery(search);

        const searchResult = taskCard.filter(
            (query: ITask) =>
                // query.nameComplete
                //     .toLowerCase()
                //     .includes(search.toLowerCase()) ||
                query.taskConfName
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                query.description
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                query.status.toLowerCase().includes(search.toLowerCase()),
        );

        if (search.length > 0) {
            setData(searchResult);
        } else {
            setData(taskCard);
        }
    };

    return (
        <div>
            {loading ? (
                <>
                    <Card loading={loading} style={{ marginBottom: '1.5em' }} />
                    <Card loading={loading} style={{ marginBottom: '1.5em' }} />
                    <Card loading={loading} />
                </>
            ) : (
                <>
                    <div
                        style={{
                            marginBottom: '1em',
                        }}
                    >
                        <CardFilter
                            query={query}
                            changeQuery={searchFilter}
                            placeholder="Filtrar por nombre, colaborador, descripcion o estado"
                        />
                    </div>
                    {data.length !== 0 ? (
                        data.map((task: any, idx) => (
                            <Card
                                style={{ marginBottom: '1.5em' }}
                                key={task.taskConfId}
                                size="small"
                                extra={
                                    <Dropdown
                                        overlay={() => menu(task)}
                                        trigger={['click']}
                                    >
                                        <a onClick={(e) => e.preventDefault()}>
                                            <MoreOutlined />
                                        </a>
                                    </Dropdown>
                                }
                            >
                                <Skeleton loading={loading} active>
                                    <Card
                                        size="small"
                                        type="inner"
                                        title="Nombre"
                                        bordered={false}
                                    >
                                        {task.taskConfName}
                                    </Card>
                                    <Card
                                        size="small"
                                        type="inner"
                                        title="Descripción"
                                        bordered={false}
                                    >
                                        {task.description}
                                    </Card>
                                    <Card
                                        size="small"
                                        type="inner"
                                        title="Usuario"
                                        bordered={false}
                                    >
                                        {task.nameComplete}
                                    </Card>
                                    <Card
                                        size="small"
                                        type="inner"
                                        title="Estado"
                                        bordered={false}
                                    >
                                        {task.status}
                                    </Card>
                                    <Button
                                        block
                                        ghost
                                        type="primary"
                                        onClick={() =>
                                            handleChange(idx, !show[idx])
                                        }
                                    >
                                        {show[idx]
                                            ? 'Ocultar detalles'
                                            : 'Ver mas detalles'}
                                    </Button>
                                    {task.tasks.map((element, index) => (
                                        <Fragment key={index}>
                                            {show[idx] && (
                                                <>
                                                    <Card
                                                        size="small"
                                                        type="inner"
                                                        title="Campo"
                                                        style={{
                                                            marginTop: '1em',
                                                        }}
                                                    >
                                                        {element.fieldName}
                                                    </Card>
                                                    <Card
                                                        size="small"
                                                        type="inner"
                                                        title="Tipo de campo"
                                                    >
                                                        {element.inputName}
                                                    </Card>
                                                    <Card
                                                        size="small"
                                                        type="inner"
                                                        title="Campo"
                                                    >
                                                        {element.franchiseName}
                                                    </Card>
                                                </>
                                            )}
                                        </Fragment>
                                    ))}
                                </Skeleton>
                            </Card>
                        ))
                    ) : (
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={NoResults}
                                style={{
                                    height: 200,
                                    width: 200,
                                    margin: '0 auto',
                                }}
                            />
                            <span style={{ color: 'grey' }}>
                                ¡Aun no hay tareas!
                            </span>
                        </div>
                    )}
                </>
            )}

            {/* <UpdateTask
                modalVisible={modalVisible}
                closeModal={closeModal}
                rowData={rowData}
            /> */}
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        tasks: state.GetTasks.tasks,
        loading: state.GetTasks.loading,
        taskCard: state.GetTasks.taskCard,
    };
};

const mapDispatchToProps = {
    getTasks,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskCard);
