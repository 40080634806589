/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect, useRef } from 'react';
import { Table, Typography, Tag, Badge } from 'antd';
import { EditTwoTone, SearchOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { RootState } from '@store/index';
import { getTasks } from '@store/Actions/GetTasks';
import { getTaskConfig } from '@store/Actions/GetTaskConfig';
import { getFieldTask } from '@store/Actions/GetFieldTask';
import { ITask } from './types';
import UpdateTask from '@components/UpdateTask';
import useFilter from '../../hooks/useFilter';
import useFilterUnique from '../../hooks/useFilterUnique';

import './TaskTable.css';

interface userNames {
    text: string;
    value: string;
}

interface Props {
    tasks: any;
    getTasks: () => void;
    getTaskConfig: () => void;
    getFieldTask: (id: number) => void;
    loading: boolean;
    fieldTask: any;
    loadingField: boolean;
}

const TaskTable: React.FC<Props> = ({
    tasks,
    getTasks,
    fieldTask,
    getFieldTask,
    loadingField,
    loading,
    getTaskConfig,
}): JSX.Element => {
    const userName = useFilterUnique({ data: tasks });

    console.log(tasks, 'tasks');

    const { getColumnSearch } = useFilter();
    const [editingKey, setEditingKey] = useState<any>('');

    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [rowData, setRowData] = useState<ITask>({
        createdDate: 0,
        description: '',
        duration: 0,
        franchiseId: 0,
        franchiseName: '',
        isCompleted: 0,
        isNew: 0,
        isOnTime: 0,
        taskConfId: 0,
        taskConfName: '',
        key: 0,
        formattedDate: '',
        formattedFinishDate: '',
        status: '',
        isTaskGroup: 0,
        users: [
            {
                file: null,
                finishedDate: null,
                isFile: false,
                isLocation: false,
                isNumericInput: false,
                isPhoto: false,
                isTextInput: false,
                location: null,
                numericinput: null,
                photo: null,
                taskId: 0,
                textinput: null,
                userId: 0,
                userLastName: '',
                userName: '',
                nameComplete: '',
                formattedFinishedDate: '',
            },
        ],
        tasks: [],
    });

    const closeModal = () => {
        setModalVisible(false);
    };

    useEffect(() => {
        getTaskConfig();
    }, []);

    const statusTask = (onTime: number, completed: number) => {
        let status = 'No completado';

        if (onTime == 1 && completed == 1) {
            status = 'Completado';
        }

        if (onTime == 0 && completed == 1) {
            status = 'No completado';
        }

        if (onTime == 1 && completed == 0) {
            status = 'En progreso';
        }

        return status;
    };

    const statusColor = (status: string) => {
        if (status === 'En progreso') {
            return <Badge status="processing" />;
        }

        if (status === 'Completado') {
            return <Badge status="success" />;
        }

        if (status === 'No completado') {
            return <Badge status="error" />;
        }
        return <Badge status={'default'} />;
    };

    const expandedRowRender = (taskConfigId) => {
        // console.log('expanded');
        // getFieldTask(taskConfigId);
        console.log(fieldTask, 'fieldTask');

        const columns: any[] = [
            {
                title: 'Nombre',
                dataIndex: 'Fieldname',
                key: 'Fieldname',
            },
            {
                title: 'Campo',
                dataIndex: 'InputName',
                key: 'InputName',
            },
            {
                title: 'Requerido',
                dataIndex: 'isrequired',
                key: 'InputName',
                render: (isRequired) => (isRequired ? 'Si' : 'No'),
            },
            {
                title: 'Min',
                dataIndex: 'min',
                key: 'InputName',
            },
            {
                title: 'Max',
                dataIndex: 'max',
                key: 'InputName',
            },
        ];

        return (
            <div
                style={{
                    paddingLeft: '2em',
                    margin: '1em',
                }}
            >
                <Table
                    columns={columns}
                    dataSource={fieldTask}
                    pagination={false}
                    loading={loadingField}
                />
            </div>
        );
    };

    const columns = [
        {
            title: 'Nombre',
            key: 'name',
            dataIndex: 'TaskName',
            width: '20%',
            editable: true,
            ...getColumnSearch('TaskName', 'nombre'),
            filterIcon: (filtered) => (
                <SearchOutlined
                    style={{ color: filtered ? '#1890ff' : undefined }}
                />
            ),
        },
        {
            title: 'Descripción',
            key: 'description',
            dataIndex: 'Description',
            width: '25%',
            editable: true,
        },
        {
            title: 'Locacion',
            key: 'franchiseName',
            dataIndex: 'FranchiseName',
            width: '20%',
            editable: true,
            filters: userName.items,
            onFilter: (value, record) =>
                record.nameComplete.indexOf(value) === 0,
            filterSearch: true,
            render: (franchises) =>
                franchises.map((franchise, idx) => {
                    let color = 'blue';
                    if (idx == 0) color = 'blue';
                    if (idx == 1) color = 'green';
                    return (
                        <Tag style={{ margin: '3px' }} color={color} key={idx}>
                            {franchise}
                        </Tag>
                    );
                }),
        },
        {
            title: 'Creacion',
            key: 'formattedDate',
            dataIndex: 'formattedDate',
            width: '20%',
            editable: true,
            filters: userName.items,
            onFilter: (value, record) =>
                record.nameComplete.indexOf(value) === 0,
            filterSearch: true,
        },
        {
            title: 'Limite',
            key: 'formattedFinishDate',
            dataIndex: 'formattedFinishDate',
            width: '20%',
            editable: true,
            filters: userName.items,
            onFilter: (value, record) =>
                record.nameComplete.indexOf(value) === 0,
            filterSearch: true,
        },
        {
            title: 'Estado',
            key: 'status',
            dataIndex: 'status',
            width: '10%',
            editable: true,
            align: 'center',
            filters: [
                {
                    text: <Badge status="processing" text="En progreso" />,
                    value: 'En progreso',
                },
                {
                    text: <Badge status="success" text="Completado" />,
                    value: 'Completado',
                },
                {
                    text: <Badge status="error" text="No completado" />,
                    value: 'No completado',
                },
            ],
            onFilter: (value: string, record: any) => record.status === value,
            // filterSearch: true,
            render: (status) => statusColor(status),
        },
        {
            key: 'operation',
            title: '',
            dataIndex: 'operation',
            render: (_: any, record: ITask) => (
                <div className="actions__icon">
                    <Typography.Link
                        disabled={editingKey !== ''}
                        onClick={() => {
                            setModalVisible(true);
                            setRowData(record);
                        }}
                    >
                        <EditTwoTone />
                    </Typography.Link>
                </div>
            ),
        },
    ];

    return (
        <>
            {/* <Form form={form} component={false}> */}
            <Table
                size="small"
                dataSource={tasks}
                loading={loading}
                // @ts-ignore
                columns={columns}
                expandable={{
                    expandedRowRender: (record: any) =>
                        expandedRowRender(record.TaskConfigId),
                    onExpand: (_, record: any) =>
                        getFieldTask(record.TaskConfigId),
                }}
            />
            {/* </Form> */}
            <UpdateTask
                modalVisible={modalVisible}
                rowData={rowData}
                closeModal={closeModal}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        tasks: state.GetTasks.tasks,
        loading: state.GetTasks.loading,
        loadingField: state.GetFieldTask.loading,
        fieldTask: state.GetFieldTask.fields,
    };
};

const mapDispatchToProps = {
    getTasks,
    getTaskConfig,
    getFieldTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskTable);
