import React from 'react';
import {
    Avatar,
    Badge,
    Menu,
    Dropdown,
    Typography,
    Skeleton,
    Space,
} from 'antd';
import CryptoJS from 'crypto-js';
import { connect } from 'react-redux';
import { RootState } from '@store/index';
import { LogOut } from '@store/Actions/Logout';
import crown from '@assets/icons/crown.png';

import './styles.css';

interface IHeader {
    LogOut: () => void;
    userAuth: {
        name: string;
        lastName: string;
        email: string;
        franchise: string;
        isDirector: boolean;
        companyName;
    };
    loading: boolean;
    isUserName: boolean; //screen width greater than 521 px = true
}

const Header: React.FC<IHeader> = ({
    userAuth,
    LogOut,
    loading,
    isUserName,
}): JSX.Element => {
    const md5Hash = CryptoJS.MD5(userAuth.email);
    const { Text } = Typography;

    const handleLogOut = () => {
        LogOut();
    };

    const menu = (
        <Menu>
            {!isUserName && (
                <>
                    <Menu.Item key="0">
                        <a>
                            {userAuth.name} {userAuth.lastName}
                        </a>
                    </Menu.Item>
                    <Menu.Item key="1">
                        <a>{userAuth.franchise}</a>
                    </Menu.Item>
                    <Menu.Divider />
                </>
            )}
            <Menu.Item key="2">
                <a href="/" onClick={() => handleLogOut()}>
                    Cerrar sesión
                </a>
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            {loading ? (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 15,
                    }}
                >
                    <Skeleton.Avatar
                        active
                        shape="square"
                        style={{ width: 100, marginRight: '1em' }}
                    />
                    <Skeleton.Avatar active shape="circle" />
                </div>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    {userAuth.isDirector && (
                        <>
                            <img
                                src={crown}
                                alt="crown"
                                style={{ width: 20, marginRight: '1em' }}
                            />
                            <Text
                                style={{
                                    color: '#AAA',
                                    marginTop: 3,
                                    fontWeight: 'bold',
                                }}
                            >
                                {userAuth?.companyName}
                            </Text>
                        </>
                    )}
                    {isUserName && (
                        <>
                            <Text
                                style={{
                                    color: '#AAA',
                                    marginTop: 3,
                                    fontWeight: 'bold',
                                    marginRight: '1em',
                                }}
                            >
                                {userAuth?.franchise}
                            </Text>
                            <span
                                style={{
                                    color: '#AAA',
                                    marginRight: '1em',
                                }}
                            >
                                •
                            </span>
                            <Text
                                style={{
                                    color: '#AAA',
                                    marginTop: 3,
                                }}
                            >
                                {userAuth?.name} {userAuth?.lastName}
                            </Text>
                        </>
                    )}
                    <Dropdown overlay={menu} trigger={['click']}>
                        <a href="">
                            <Badge>
                                <Avatar
                                    style={{ marginLeft: '1em' }}
                                    shape="circle"
                                    src={`https://www.gravatar.com/avatar/${md5Hash.toString()}?d=retro`}
                                />
                            </Badge>
                        </a>
                    </Dropdown>
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        userAuth: state.IsAuth.userAuth,
        loading: state.IsAuth.loading,
    };
};

const mapDispatchToProps = {
    LogOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
