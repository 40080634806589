import React, { useState, useEffect, Fragment } from 'react';
import moment, { Moment } from 'moment';
import {
    Form,
    Input,
    Modal,
    Dropdown,
    Menu,
    Button,
    DatePicker,
    Typography,
    Row,
    Col,
    Select,
    Switch,
    Divider,
} from 'antd';
import { CloseOutlined, DownOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { updateTask } from '../../core/store/Actions/UpdateTask';
import { getTasks } from '../../core/store/Actions/GetTasks';
import { RootState } from '@store/index';
import { ITask } from '@components/TaskTable/types';
import { SelectInput } from '@pages/CreateTask/SelectInput';

interface Props {
    modalVisible: boolean;
    rowData: ITask;
    closeModal: () => void;
    updateTask: (
        taskConfId: number,
        name: string,
        description: string,
        duration: number,
        isCompleted: number,
        isOnTime: number,
        isNew: number,
        values: any[],
        fieldId: number,
        inputTypeId: number,
        isRequired: number,
        min: number,
        max: number,
        fieldName: string,
        isTaskGroup: number,
        addUsers: number[],
        deleteUsers: number[],
        tasks: any[],
    ) => void;
    loading: boolean;
    getTasks: () => void;
    userAuth: {
        userId: number;
    };
    collaborators: any[];
}

const UpdateTask: React.FC<Props> = ({
    modalVisible,
    rowData,
    closeModal,
    updateTask,
    loading,
    getTasks,
    userAuth,
    collaborators,
}): JSX.Element => {
    const arrayTask: any = [];

    const [status, setStatus] = useState<string>('');
    const [taskOnTime, setTaskOnTime] = useState<any>(undefined);
    const [taskCompleted, setTaskCompleted] = useState<any>(undefined);
    const [time, setTime] = useState<number>(0);
    const [error, setError] = useState<boolean>(false);
    const [taskGroup, setTaskGroup] = useState<boolean>(false);
    const [inputType, setInputType] = useState<number>(0);
    const [addUser, setAddUser] = useState<any>(0);
    const [deleteUser, setDeleteUser] = useState<any>(0);

    const [fieldName, setFieldName] = useState<string>('');
    const [addField, setAddField] = useState<any>([]);

    const [form] = Form.useForm();

    const { Text } = Typography;
    const { Option } = Select;

    useEffect(() => {
        if (rowData?.isOnTime == 1 && rowData.isCompleted == 1) {
            setStatus('Completado');
        }
        if (rowData?.isOnTime == 1 && rowData.isCompleted == 0) {
            setStatus('En progreso');
        }
        if (rowData?.isOnTime == 0 && rowData.isCompleted == 1) {
            setStatus('No completado');
        }

        form.setFieldsValue({
            name: rowData.taskConfName,
            description: rowData.description,
        });

        setTime(rowData.duration);
        rowData.isTaskGroup === 1 ? setTaskGroup(true) : setTaskGroup(false);
    }, [rowData, arrayTask]);

    rowData.tasks.forEach((task) => {
        arrayTask.push({
            name: task.fieldName,
            inputFieldId: task.inputTypeId,
            isRequired: task.isRequired,
            min: task.min,
            max: task.max,
        });
    });

    const range = (start, end) => {
        const result: any = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };

    const disabledDate = (current: Moment) => {
        // Can not select days before today
        return current && current < moment().startOf('day');
    };

    const disabledTime = (current) => {
        if (current && current.date() > new Date().getDate()) {
            return {};
        }
        return {
            disabledHours: () => range(0, current?.hour() || 0),
            disabledMinutes: () => range(0, current?.minute() || 0),
        };
    };

    const overlay = () => (
        <Menu onClick={(status) => handleStatus(status.key)}>
            <Menu.Item key="0">En progreso</Menu.Item>
            <Menu.Item key="1">Completado</Menu.Item>
            <Menu.Item key="2">No Completado</Menu.Item>
        </Menu>
    );

    const handleStatus = (status) => {
        if (status === '0') {
            setTaskOnTime(1);
            setTaskCompleted(0);
            setStatus('En progreso');
        }

        if (status === '1') {
            setTaskOnTime(1);
            setTaskCompleted(1);
            setStatus('Completado');
        }

        if (status === '2') {
            setTaskOnTime(0);
            setTaskCompleted(1);
            setStatus('No completado');
        }
    };

    const onChangeTime = (date) => {
        setTime(date.valueOf());
    };

    const isCompletedTask = (): number => {
        let epoch = new Date().valueOf();

        return Math.floor(epoch / 1000);
    };

    const onOk = async () => {
        const values = await form.validateFields();
        const deadLineSeconds = Math.floor(time / 1000) || time;

        console.log(values, 'values');

        // await updateTask(
        //     rowData?.taskConfId,
        //     values.name,
        //     values.description,
        //     status === 'En progreso' ? deadLineSeconds : rowData?.duration,
        //     userAuth.userId,
        //     status === 'Completado' ? isCompletedTask() : rowData?.finishedDate,
        //     taskCompleted !== undefined ? taskCompleted : rowData?.isCompleted,
        //     taskOnTime !== undefined ? taskOnTime : rowData?.isOnTime,
        //     rowData?.isNew,
        //     rowData?.tasks,
        // );

        form.resetFields();
        closeModal();
        getTasks();
    };

    const handleAdd = (add) => {
        add();
        setError(false);
    };

    const options = collaborators.map((collaborator) => (
        <Option
            value={collaborator.userId}
            key={collaborator.userId}
            label={`${collaborator.name} ${collaborator.lastName}`}
        >
            {collaborator.name} {collaborator.lastName}
        </Option>
    ));

    return (
        <Modal
            visible={modalVisible}
            title={`Editando: ${rowData?.taskConfName}`}
            onCancel={() => closeModal()}
            cancelText="Cancelar"
            okText="Editar"
            confirmLoading={loading}
            onOk={() => onOk()}
            destroyOnClose
        >
            <Form form={form} layout="vertical" preserve={false}>
                <Form.Item name="name" label="Nombre">
                    <Input type="text" placeholder={rowData?.taskConfName} />
                </Form.Item>
                <Form.Item name="description" label="Descripción">
                    <Input type="text" placeholder={rowData?.description} />
                </Form.Item>

                <Form.Item name="status" label="Estado">
                    <Dropdown overlay={() => overlay()} trigger={['click']}>
                        <Button onClick={(e) => e.preventDefault()}>
                            {status}
                            <DownOutlined />
                        </Button>
                    </Dropdown>
                </Form.Item>
                {status === 'En progreso' && (
                    <Form.Item name="time" label="Fecha de entrega">
                        <DatePicker
                            placeholder="Fecha final"
                            defaultValue={moment.unix(1646093764)}
                            showTime
                            onChange={onChangeTime}
                            disabledDate={disabledDate}
                            disabledTime={disabledTime}
                            format="YYYY/MM/DD HH:mm:ss"
                            style={{ width: '100%' }}
                            showSecond={false}
                        />
                    </Form.Item>
                )}
                <Row gutter={24}>
                    <Col xs={24} sm={19}>
                        <Form.Item
                            label="Colaboradores"
                            name="collaborators"
                            initialValue={rowData.users.map(
                                (collaborator) => collaborator.userId,
                            )}
                            rules={[
                                {
                                    required: true,
                                    message: 'Ingrese al menos 1 colaborador',
                                },
                            ]}
                        >
                            <Select
                                optionFilterProp="label"
                                mode="multiple"
                                placeholder="Nombre de colaboradores"
                                onSelect={(value, option) => setAddUser(value)}
                                onDeselect={(value, option) =>
                                    setDeleteUser(value)
                                }
                            >
                                {options}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={5}>
                        <Form.Item
                            label="Tarea grupal"
                            name="groupTask"
                            initialValue={false}
                        >
                            <Switch
                                checked={taskGroup}
                                onChange={setTaskGroup}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.List name="tasks" initialValue={arrayTask}>
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => {
                                return (
                                    <Fragment key={field.key}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Text type="secondary">
                                                {index + 1}.
                                            </Text>
                                            <CloseOutlined
                                                style={{ color: '#546B81' }}
                                                onClick={() =>
                                                    remove(field.name)
                                                }
                                            />
                                        </div>
                                        <Form.Item
                                            label="Nombre del campo"
                                            name={[field.name, 'name']}
                                            fieldKey={field.fieldKey}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Ingrese el nombre del campo',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item fieldKey={field.fieldKey}>
                                            <SelectInput field={field} />
                                        </Form.Item>

                                        <Divider />
                                    </Fragment>
                                );
                            })}
                            <Button
                                type="dashed"
                                onClick={() => handleAdd(add)}
                                block
                                icon={<PlusOutlined />}
                            >
                                Añadir campo
                            </Button>
                        </>
                    )}
                </Form.List>
            </Form>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        loading: state.UpdateTask.loading,
        userAuth: state.IsAuth.userAuth,
        collaborators: state.ActiveUsers.activeUsers,
    };
};

const mapDispatchToProps = {
    updateTask,
    getTasks,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateTask);
