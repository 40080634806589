import { AnyAction } from 'redux';

interface getTasks {
    tasks: string[];
    loading: boolean;
    taskCard: [];
}

const initialState: getTasks = {
    tasks: [],
    loading: false,
    taskCard: [],
};

export const GetTasks = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case 'GET_TASKS':
            const taskData: any = [];

            const statusTask = (onTime: number, completed: number): string => {
                let status = 'No completado';

                if (onTime == 1 && completed == 1) {
                    status = 'Completado';
                }

                if (onTime == 0 && completed == 0) {
                    status = 'No completado';
                }

                if (onTime == 1 && completed == 0) {
                    status = 'En progreso';
                }

                return status;
            };

            action.payload.forEach((element: any) => {
                taskData.push({
                    ...element,
                    key: element.TaskConfigId,
                    status: statusTask(element.isOnTime, element.isCompleted),
                    formattedDate: new Date(
                        element.CreatedDate * 1000,
                    ).toLocaleDateString(),
                    formattedFinishDate: new Date(
                        element.Duration * 1000,
                    ).toLocaleDateString(),
                });
            });

            return {
                ...state,
                tasks: taskData,
            };
        case 'LOADING_TASKS':
            return {
                ...state,
                loading: action.payload,
            };

        default:
            return state;
    }
};
